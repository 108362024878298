<template>
  <div class="hello" @click="hideAutoComplete">
    <van-swipe :autoplay="3000" :height="419" v-if="!ftop">
      <van-swipe-item :default="topImg" class="car-new-panel"
        >
        <div class="car-new-search">
          <img src="https://img.tglmall.com.cn/upload_pic_ccb-search_bg.png-6144"/>
          <div class="car-find">
            <input v-model="keyword" placeholder="请输入车企关键词" @input = "filterCar"/>
            <div @click="resetSearchBtn" v-if="keyword.length > 0" class="car-reset">x</div>
            <div class="car-search" @click="searchBtn">去搜索</div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="car-f-new-search">
      <div class="car-new-search" v-if="ftop">
        <img src="https://img.tglmall.com.cn/upload_pic_ccb-search_bg.png-6144"/>
        <div class="car-find">
          <input v-model="keyword" placeholder="请输入车企关键词" @input = "filterCar"/>
          <div @click="resetSearchBtn" v-if="keyword.length > 0" class="car-reset">x</div>
          <div class="car-search" @click="searchBtn">去搜索</div>
        </div>
      </div>
    </div>
    <div class="autoComplete" v-if="filterList.length > 0">
      <div class="autoComplete-item" v-for="item in filterList" @click="selectCar(item)"
        :key="item.id"
      >
        <div class="car-find-name" v-html="item.tname"></div>
        <div class="car-distance">{{item.distance}}</div>
      </div>
    </div>
    <div v-if="locationLoaded" :style="ftop?'margin-top:140px':''">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          v-for="item in list"
          :key="item.id"
          class="car-card"
        >
          <div class="car-img">
            <img :src="item.placeMainImage" @error="nofind"/>
          </div>
          <div class="car-info">
            <div class="car-title">{{item.placeName}}</div>
            <div class="car-star">
              <div class="car-brand">{{item.companyName}}</div>
              <div class="car-star-bg" v-if="false">
                <van-rate size="10" v-model="rateValue" color="#F96501"/>
              </div>
            </div>
            <div class="car-call" v-for="(_item, _index) in item.placeDescribe.split('、')" :key="_item" style="margin-bottom: 5px;'">
              <div class="car-phone"><a :href="'tel:' + _item">{{_item}}</a></div>
              <div class="car-distance" v-if="_index==0">
                {{item.distance}}km
              </div>
            </div>
            <div class="car-address">{{item.address}}</div>
            <div class="car-location">
              <div v-if="false" class="car-coupon" @click="carCoupon(item)">去领券</div>
              <div class="car-pay" @click="carPay(item)"></div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant-green';
import { getCarList,getCarNameList,login,getConfig } from '@/api/apis.js'
export default {
  name: 'Index',
  data() {
    return {
      typeId:'12',
      locationLoaded:false,
      ccbParamSJ:this.$route.query.ccbParamSJ || '',
      ftop:false,
      pageNumber:1,
      pageSize:10,
      lng:116.46542060395913,
      lat:39.97456040536952,
      keyword:'',
      filterId:'',
      value: '',
      showAction: false,
      list: [],
      loading: false,
      finished: false,
      rateValue: 5,
      filterList:[],
      carList: [],
      carNames:[],
      title:'汽车消费券',
      topImg:'https://img.tglmall.com.cn/new/wd_default1.png',
      couponUrl:''
    };
  },
  watch: {
      $route(to, from){
        console.log('目标路径', to.path)
        if (to.path == '/newIndex') {
          document.title = this.title;
        }
      },
  },
  mounted () {
    document.title = this.title;
    this.initConfig();
    window.positionCallBackFund = this.positionCallBack;
    window.closePageCallBackFund = this.onClosePage;
    console.log('load position begin')
    try {
      this.getLocation();
    } catch (error) {
      console.log(error);
    }
    console.log('load position end')
    try {
      this.autoLogin();
    } catch (error) {
       console.log(error);
    }
    console.log('autoLogin end')
    try {
      this.loadNameList();
    } catch (error) {
       console.log(error);
    }
    console.log('loadNameList end')
  },
  methods: {
    nofind(e) {
      e.target.src = 'https://img.tglmall.com.cn/wd/000.png';
    },
    onClosePage() {

    },
    initConfig() {
      let that = this;
      getConfig(that.typeId).then(res => {
        that.title = res.data.title;
        document.title = that.title;
        that.topImg = res.data.topImg;
        that.couponUrl = res.data.url;
      }).catch(err => { console.log('获取类别失败', err); })
    },
    autoLogin() {
      let that = this;
      login(that.ccbParamSJ).then(res => {
        if (res.data.code == 200) {
          localStorage.setItem('token', res.data.token);
        }
      }).catch(err => { console.log('获取类别失败', err); })
    },
    locationFail() {
      let that = this;
      Dialog.confirm({
        title: '请授权后再参与活动',
        message: '是否重新获取位置信息？',
          confirmButtonText: '重新获取',
          cancelButtonText: '退出'
      })
      .then(() => {
        that.getLocation();
      })
      .catch(() => {
        that.closePage();
      });
    },
    locationCityFail() {
      let that = this;
      Dialog.alert({
        message: '非常抱歉，本活动仅限北京用户参与'
      })
      .then(() => {
        that.closePage();
      })
    },
    closePage() {
      try {
        console.log('closePage');
        let t = {
          action:'closePage',
          params:{
          }
        };
        window.CCBBridge.requestNative(JSON.stringify(t),"closePageCallBackFund")
      } catch (error) {
        console.log(error);
      }
    },
    positionCallBack(resp) {
      console.log(resp);
      console.log(typeof resp);
      try {
        var data = JSON.parse(resp);
        if (typeof data != 'undefined' && data.data != 'undefined' && data.state == "0") {
          console.log('local success')
          if (data.data.cityName.indexOf('北京') == -1 && data.data.cityName.indexOf('武汉') == -1) {
            //this.locationCityFail();
            this.lat = data.data.ltt;
            this.lng = data.data.lgt;
            this.locationLoaded = true;
          } else {
            this.lat = data.data.ltt;
            this.lng = data.data.lgt;
            this.locationLoaded = true;
          }
        } else {
          this.locationFail();
          console.log('fail success')
        }
      } catch (error) {
        console.log(error);
        this.locationFail();
      }
    },
    getLocation() {
      console.log('load position ...' + window.CCBBridge)

      if (typeof window.CCBBridge != 'undefined') {
        try {
          console.log('load position ...' + window.CCBBridge.requestNative)
          let t = {
            action:'position',
            params:{
              platformId:"YS44000009001402",
              requestReason:"",
              needLocate:true,
              needCityLocation:false,
              cityName:''
            }
          };
          window.CCBBridge.requestNative(JSON.stringify(t),"positionCallBackFund")
        } catch (error) {
          this.locationFail();
          console.log(error);
        }
      } else {
        this.locationLoaded = true;
      }
    },
    addScrollHandler(data) {
      let that = this;
      window.addEventListener('scroll', function(e) {
        const windowHeight = window.screen.height;
        //获取页面滚动距离
        const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        if (!that.ftop) {
          that.ftop = scrollTop > 290;
        } else if (scrollTop <= 0) {
          that.ftop = false;
        }
        console.log(scrollTop);
      });
    },
    handleScroll(e) {
      console.log(e);
    },
    getDistance: function (lat1, lng1, lat2, lng2) {
      var that = this;
      lat1 = lat1 || 0;
      lng1 = lng1 || 0;
      lat2 = lat2 || 0;
      lng2 = lng2 || 0;
      var rad1 = lat1 * Math.PI / 180.0;
      var rad2 = lat2 * Math.PI / 180.0;
      var a = rad1 - rad2;
      var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
      var r = 6378137;
      var distance = r * 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(rad1) * Math.cos(rad2) * Math.pow(Math.sin(b / 2), 2)));
      return distance.toFixed(2);
    },
    loadNameList() {
      let that = this;
      getCarNameList(that.typeId).then(res => {
        if (res.data.code == 200) {
          this.carNames = res.data.data;
        }
      }).catch(err => { console.log('获取类别失败', err); })
    },
    onLoad() {
      if (this.locationLoaded) {
        console.log('onLoad');
        this.loadData();
      }
    },
    hideAutoComplete() {
      this.filterList = [];
    },
    searchBtn() {
      this.finished = true;
      this.filterId = '';
      this.filterList = [];
      this.list = [];
      this.pageNumber = 1;
      console.log('searchBtn');
      this.loadData();
    },
    carPay(item) {
      console.log(item);
      let that = this;
      this.GLOBAL.item = item;
      this.$router.push({path:'/detail', query: {
        "id": item.id,
        "lat": that.lat,
        "lng": that.lng
      }});
    },
    carCoupon(item) {
      window.location.href = this.couponUrl;
    },
    selectCar(item) {
      this.filterId = item.id;
      this.keyword = item.name;
      this.filterList = [];
      this.filterLoadList();
    },
    resetSearchBtn() {
      this.filterId = '';
      this.keyword = '';
      this.filterList = [];
    },
    distinceSort(n1, n2) {
      return n1.distanceNum-n2.distanceNum;
    },
    filterCar(event) {
      console.log('filterCar');
      this.filterId = '';
      this.keyword = event.target.value;
      console.log(this.keyword);
      if (this.keyword.trim() == '') {
        this.filterList = [];
      } else {
        this.filterList = this.carNames.filter(item => {
          return item.name.indexOf(this.keyword) != -1;
        })
        if (this.filterList.length > 0) {
            if (this.filterList.length > 15) {
              this.filterList = this.filterList.slice(0, 15);
            }
            for (var i = 0; i < this.filterList.length; i++) {
              this.filterList[i].tname = this.filterList[i].name.replace(this.keyword, '<span style="color: #F96501;">' + this.keyword + '</span>');
              this.filterList[i].distance = '';
              let hw = this.getDistance(this.lat, this.lng, this.filterList[i].lat, this.filterList[i].lng);
              this.filterList[i].distanceNum = hw;
              this.filterList[i].distance = hw +"米";
              if (hw && hw !== -1 && hw > 1000) { //拿到正确的值
                  //转换成公里
                  hw = (hw / 2 / 500).toFixed(2) + 'km'
                  this.filterList[i].distance = hw;
              } else {
                  this.filterList[i].distance = "100米内";
              }
            }
            this.filterList = this.filterList.sort(this.distinceSort);
        }
        console.log(this.filterList);
      }
    },
    filterLoadList() {
      let that = this;
      that.pageNumber = 1;
      that.finished = true;
      that.list = [];
      console.log('filterLoadList')
      getCarList(this.pageNumber, this.pageSize, this.lng, this.lat, this.filterId, '', this.typeId).then(res => {
        if (res.data.code == 200) {
          // 加载状态结束
          that.loading = false;
          that.pageNumber = that.pageNumber + 1;
          // 数据全部加载完成
          if (res.data.data.length < that.pageSize) {
            that.finished = true;
            if (res.data.data.length > 0) {
              that.list = that.list.concat(res.data.data);
            }
          } else {
            that.finished = false;
            that.list = that.list.concat(res.data.data);
          }
        }
      }).catch(err => { console.log('获取类别失败', err); })
    },
    loadData() {
      console.log('loadData 111')
      let that = this;
      getCarList(this.pageNumber, this.pageSize, this.lng, this.lat, '', this.keyword, this.typeId).then(res => {
        if (res.data.code == 200) {
          // 加载状态结束
          that.loading = false;
          that.pageNumber = that.pageNumber + 1;
          // 数据全部加载完成
          if (res.data.data.length < that.pageSize) {
            that.finished = true;
            if (res.data.data.length > 0) {
              that.list = that.list.concat(res.data.data);
            }
          } else {
            that.finished = false;
            that.list = that.list.concat(res.data.data);
          }
        }
      }).catch(err => { console.log('获取类别失败', err); })
    },
    onSearch() {
      this.$toast(this.value);
    },
    onFocus() {
      console.log('11111');
      this.showAction = true;
    },
    onCancel() {
      this.showAction = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
  background: #F6F7F8;
}
.car-reset {
  width: 28px;
  height: 28px;
  background: linear-gradient(270deg, #FBA149 0%, #FE6402 100%);
  border-radius: 6px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 28px;
}
.car-f-new-search {
  position: fixed;
  background: #fff;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
}
.van-rate {
  margin-top:2px;
  margin-right:5px;
}
.car-new-panel {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.car-new-search {
  width: 355px;
  height: 126px;
  margin: 0px 0px 10px 0px;
}
.car-new-search img {
  width: 355px;
  height: 126px;
}
.car-card {
  margin: 15px auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  width: 95%;
  min-height: 150px;
  background: #FFFFFF;
  border-radius: 16px;
}
.car-img {
  width: 84px;
  height: 84px;
  background: #D8D8D8;
  border-radius: 8px;
}
.car-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.car-info {
  text-align: left;
  font-size: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
}
.car-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 13px;
  color: #25282A;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.car-call {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.car-find-name {
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-left.png-0698) left 0px no-repeat;
  background-size: 16px 16px;
  padding-left:26px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #141414;
  width:80%;
}
.car-l-color {
  color: #F96501;
}
.car-phone {
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-phone.png-6142) no-repeat;
  height: 17px;
  width: 111px;
  line-height:17px;
  background-size: 111px 17px;
  display: flex;
  justify-content: flex-end;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #607088;
  padding-right:3px;
}
.car-phone a {
  font-size: 12px;
  color: #607088;
  text-decoration: none; /* 去掉下划线 */
}
.car-star {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0px 0px 3px 0px;
}
.car-star-bg {
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-review.png-6143) no-repeat;
  height: 17px;
  width: 111px;
  background-size: 111px 17px;
  display: flex;
  justify-content: flex-end;
}
.car-location {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top:15px;
}
.car-address {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(37,40,42,0.6);
  line-height: 17px;
  text-align: left;
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-location.png-6142) left 3px no-repeat;
  background-size: 10px 10px;
  padding-left:15px;
  margin:3px 0px 3px 0px;
}
.car-distance {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 12px;
  color: #25282A;
  text-align: center;
  font-style: normal;
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-distance.png-6139) left center no-repeat;
  background-size: 8px 8px;
  padding-left:12px;
}
.car-pay {
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-go-pay.png-6140) no-repeat;
  height: 28px;
  width: 80px;
  background-size: 80px 28px;
}
.car-coupon {
  background: linear-gradient( 270deg, #FBA149 0%, #FE6402 100%);
  height: 28px;
  width: 80px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  margin-right: 16px;
  font-weight: bold;
}
.car-find {
  margin: 15px;
  border: 2px solid #F96501;
  border-radius: 5px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
  position: absolute;
  width: 325px;
  bottom: 10px;
}
.car-find input {
  width: 60%;
  height: 80%;
  border: none;
  outline: none;
  font-size: 12px;
  background: transparent;
}
.car-search {
  width: 80px;
  height: 28px;
  background: linear-gradient( 270deg, #FBA149 0%, #FE6402 100%);
  border-radius: 6px;
  font-size: 12px;
  color: #FFFFFF;
  line-height:28px;
}
.autoComplete {
  width: calc(100% - 30px);
  border: 2px solid #F96501;
  margin: 10px 15px 0px 15px;
  border-radius: 5px;
  text-align: left;
  position: absolute;
  z-index: 999;
  background: #fff;
}
.autoComplete-item {
  margin: 0px 5px;
  min-height: 42px;
  border-bottom: 1px solid #F4F4F4;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px 5px 0px;
}
.autoComplete-item:last-child {
    /**border-bottom: none;**/
}

.car-brand {
  /**background: linear-gradient(270deg, #FBA149 0%, #FE6402 100%);
  color: #fff;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;**/
  color: rgba(37, 40, 42, 0.6);
}
</style>
